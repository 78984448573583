import "./App.css";
import { useEffect } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIOS,
} from "react-device-detect";
import Home from "./images/home.png";
import Logo from "./images/logo.png";
import Plug from "./images/plug.png";
import Facebook from "./images/facebook.png";
import Instagram from "./images/instagram.png";
import YouTube from "./images/youtube.png";
import Pinterest from "./images/pinterest.png";

function App() {
  const url = `https://caretools.octtone.io/visit`;

  const goToAppStore = () => {
    window.location.href =
      "https://apps.apple.com/app/apple-store/id1493998000?pt=119704790&ct=Fox%20TV%20Campaign%20Feb&mt=8";
  };
  const goToFacebook = () => {
    window.location.href = "https://www.facebook.com/omysoulapp/";
  };

  const goToPinterest = () => {
    window.location.href = "https://pinterest.com/omysoulapp";
  };
  const goToYoutube = () => {
    window.location.href =
      "https://www.youtube.com/channel/UCHxnYucaCHPh9VptDUj2Yog";
  };
  const goToInstagram = () => {
    window.location.href = "https://www.instagram.com/omysoulapp/?hl=en";
  };


  async function callEndpoint(platform) {
    const a = fetch(`https://caretools.octtone.io/visit/${platform}`, {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => console.log(res));
  }


  useEffect(() => {

  }, []);

  if (isIOS) {
    callEndpoint('ios')
    window.location.href =
      "https://apps.apple.com/us/app/o-my-soul-christian-meditation/id1493998000";
    console.log("iOS");
    return null;
  } else if (isMobile) {
    callEndpoint('mobile')
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 0.62,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <img
            alt=""
            style={{
              position: "absolute",
              top: 0,
              height: 0,
              width: "100%",
              height: "100%",
              zIndex: -3,
            }}
            src={Home}
          />
          <img
            alt="O My Soul Logo"
            style={{ width: "37%", marginTop: "-20%", height: "auto" }}
            src={Logo}
            onClick={goToAppStore}
          />
          <h1 style={{ color: "white" }}>O My Soul</h1>
          <img
            alt="O My Soul AppStore"
            onClick={goToAppStore}
            style={{ width: "40%", height: "auto", tintColor: "white" }}
            src={Plug}
          />
        </div>

        <div
          style={{
            zIndex: 11,
            flex: 0.38,
            backgroundColor: "#F7F8FA",
            borderTopRightRadius: 50,
            borderTopLeftRadius: 50,
            marginTop: -100,
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <p style={{ fontSize: "22px", color: "#6F7070" }}>
              Connect With Us
            </p>
            <hr className="line" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "12%",
              }}
            >
              <button onClick={goToFacebook} className="social">
                <img style={{ width: "auto", height: "50%" }} src={Facebook} />
              </button>
              <button onClick={goToPinterest} className="social">
                <img style={{ width: "auto", height: "50%" }} src={Pinterest} />
              </button>
              <button onClick={goToInstagram} className="social">
                <img style={{ width: "auto", height: "50%" }} src={Instagram} />
              </button>
              <button onClick={goToYoutube} className="social">
                <img style={{ width: "auto", height: "50%" }} src={YouTube} />
              </button>
            </div>
            <button onClick={goToAppStore} className="downloadButton">
              <b style={{ fontSize: 18, color: "white" }}>Download Now</b>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    callEndpoint('desktop')
    window.location.href = "https://omysoulapp.com";
    console.log("desktop");
    return null;
  }
}

export default App;
